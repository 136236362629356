import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BigShortService {
  baseUrl: string = environment.apiUrl;

  isInitialized = signal(false);
  isBigShortChallengeActive = signal(false);
  staticBanners = signal<any[]>([]);

  constructor(private readonly http: HttpClient) {}

  initializeBigShortAndStaticBannerData(): void {
    if (!this.isInitialized()) {
      this.http
        .get(`${this.baseUrl}/viewer/film/bigShortStaticBanner`)
        .subscribe({
          next: (res: any) => {
            this.isBigShortChallengeActive.set(
              res.data.bigShortChallenge === true ||
                res.data.bigShortChallenge === 'true'
            );
            this.staticBanners.set(res.data?.banners ?? []);
            this.isInitialized.set(true);
          },
          error: (error) => {
            console.error('Error fetching BigShort data:', error);
          },
        });
    }
  }
}
