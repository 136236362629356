import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { UserCountryService } from './public/shared/services/user-country.service';
import { SharedModule } from './pages/shared/shared.module';
import { CookieService } from './core/services/cookie.service';

initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    SharedModule,
  ],
  providers: [
    UserCountryService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCountryCode,
      deps: [UserCountryService],
      multi: true,
    },
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    ScreenTrackingService,
    UserTrackingService,
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function getLocalStorage(): any {
  return typeof window !== 'undefined' ? window.localStorage : null;
}

export function initializeCountryCode(
  userCountryService: UserCountryService
): () => Promise<void> {
  return () => userCountryService.initialCountryCodeSetupByIp();
}
