import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  LanResponse,
  AgentResponse,
  PresignedUrlResponse,
} from '../interfaces/upload-video.interface';
import { ApiResponseModel } from '@app/core/models/api-response.model';
import { MovieModel, SearchModel } from '@app/core/models/movie.model';
import { FilmContestModel } from '@app/core/models/film-contest.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadFilmService {
  baseUrl: string = environment.apiUrl;

  constructor(public http: HttpClient) { }

  uploadIamges(filmInfo: any, type?: string): Observable<any> {
    let url = `${this.baseUrl}/film-maker/film`;
    url = type ? `${url}?type=${type}` : url;
    return this.http.post<any>(url, filmInfo);
  }

  getAgent(): Observable<AgentResponse> {
    return this.http.get<AgentResponse>(`${this.baseUrl}/agent`);
  }

  getLanguage(): Observable<LanResponse> {
    return this.http.get<LanResponse>(`${this.baseUrl}/language`);
  }

  getSubTitle(file: any, type: string): Observable<LanResponse> {
    return this.http.post<LanResponse>(
      `${this.baseUrl}/film-maker/film/upload-subtitle`,
      file
    );
  }

  getAge(): Observable<LanResponse> {
    return this.http.get<LanResponse>(`${this.baseUrl}/film/age-group`);
  }

  uploadVideoToS3(url: string, film: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': film.type,
      'skip-Auth': 'true'
    });
    return this.http.put<any>(
      url, film, {
      reportProgress: true,
      observe: 'events',
      headers,
    }).pipe(
      map((event: HttpEvent<any>) => this.getEventProgress(event))
    );
  }

  getPresignedUrl(type: string, extention: string, contentType: string): Observable<PresignedUrlResponse> {
    let params = new HttpParams()
      .set('type', type)
      .set('extension', extention)
      .set('contentType', contentType);

    return this.http.get<any>(`${this.baseUrl}/film-maker/film/generatePresignedUrl`, { params });
  }

  private getEventProgress(event: HttpEvent<any>): number {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        // return event.total ? Math.round(100 * event.loaded / event.total) : 0;
        return Math.round((100 * event.loaded) / (event.total ?? 1));
      case HttpEventType.Response:
        return 100; // Upload complete
      default:
        return 0;
    }
  }

  uploadVideo(film: any, videoType: string): Observable<any> {
    const headers = new HttpHeaders({ 'ngsw-bypass': '' });
    return this.http.post<any>(
      `${this.baseUrl}/film-maker/film/upload?type=${videoType}`,
      film,
      {
        reportProgress: true,
        observe: 'events',
        headers,
      }
    );
  }

  getFilmList(): Observable<ApiResponseModel<MovieModel[]>> {
    return this.http.get<ApiResponseModel<MovieModel[]>>(
      `${this.baseUrl}/film-maker/filmList`
    );
  }

  getOpratingCostByTime(
    duration: number,
    filmContest: boolean
  ): Observable<LanResponse> {
    return this.http.get<LanResponse>(
      `${this.baseUrl}/ticket-price?duration=${duration}&isContest=${filmContest}`
    );
  }

  getFilmById(filmId: string | null): Observable<ApiResponseModel<MovieModel>> {
    return this.http.get<ApiResponseModel<MovieModel>>(
      `${this.baseUrl}/film-maker/film?id=${filmId}`
    );
  }

  saveAsDraft(filmData: any): Observable<ApiResponseModel<MovieModel>> {
    return this.http.post<ApiResponseModel<MovieModel>>(
      `${this.baseUrl}/film-maker/save-as-draft`,
      filmData
    );
  }

  searchFilm(
    key: string,
    page: number,
    limit: number,
    isSearchPage: boolean
  ): Observable<ApiResponseModel<SearchModel>> {
    return this.http.get<ApiResponseModel<SearchModel>>(
      `${this.baseUrl}/viewer/film/search/${encodeURIComponent(key)}?page=${page}&limit=${limit}&isSearchPage=${isSearchPage}`
    );
  }

  filmContestDetail(): Observable<ApiResponseModel<FilmContestModel>> {
    return this.http.get<ApiResponseModel<FilmContestModel>>(
      `${this.baseUrl}/film/contest/detail`
    );
  }

  filmContestLanguage(): Observable<LanResponse> {
    return this.http.get<LanResponse>(`${this.baseUrl}/film/contest/languages`);
  }

  getPricingDetail(param: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/film-maker/calculate/filmPrice`,
      param
    );
  }

  getFilteredTransaction() {
    return this.http.get(`${this.baseUrl}/wallet/filmMaker/transactions`);
  }

}
