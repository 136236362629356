import { WindowRefService } from '@app/pages/shared/services/windowref.service';
import {
  Component,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { SpinnerService } from '@core/services/spinner.service';
import { FirebaseAnalyticsService } from './core/services/firebase-analytics.service';
import { CookieService } from './core/services/cookie.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ABC Talkies';
  message: any = null;

  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly winRef: WindowRefService,
    public abcSpinner: SpinnerService,
    private readonly analyticsService: FirebaseAnalyticsService,
    private readonly cookieService: CookieService,
    private readonly deviceService: DeviceDetectorService,
  ) {
    this.analyticsService.logCustomEvent('app_open', {
      component: 'AppComponent',
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          (window as any).ga('set', 'page', event.urlAfterRedirects);
          (window as any).ga('send', 'pageview');
        }
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.checkVersion();
      this.addFirebase();
      const currentURL = this.router.url;
      const cookie = this.cookieService.getCookieByName('kGlnOFjvPy');
      if (this.deviceService.isMobile() && (currentURL == '' || currentURL == '/') && cookie !== '1') {
        if (this.deviceService.os.toLowerCase() == 'ios') {
          this.router.navigate(['static/device/ios']);
        } else if (this.deviceService.os.toLowerCase() == 'android') {
          this.router.navigate(['static/device/android']);
        }
      }
    }
  }

  checkVersion(): void {
    const versionUrl = `assets/version.json?v=${new Date().getTime()}`; // Cache-busting query parameter

    fetch(versionUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch version');
        }
        return response.json();
      })
      .then((data: { appVersion: string }) => {
        const currentVersion = localStorage.getItem('appVersion');
        if (!currentVersion || currentVersion !== data.appVersion) {
          localStorage.setItem('appVersion', data.appVersion);

          // Automatically bust cache for all assets
          const links = document.querySelectorAll(
            'link[rel="stylesheet"], script'
          );
          links.forEach((resource) => {
            const srcOrHref =
              resource.getAttribute('href') ?? resource.getAttribute('src');
            if (srcOrHref) {
              const cacheBustedUrl = `${srcOrHref.split('?')[0]
                }?v=${new Date().getTime()}`;
              if (resource.tagName === 'LINK') {
                resource.setAttribute('href', cacheBustedUrl);
              } else if (resource.tagName === 'SCRIPT') {
                resource.setAttribute('src', cacheBustedUrl);
              }
            }
          });

          window.location.reload(); // Reload the page to apply changes
        }
      })
      .catch((error) => {
        console.error('Error fetching version:', error);
      });

    /*
    Version code - required 
    if (!currentVersion || currentVersion !== environment.appVesion) {
      localStorage.setItem('appVersion', environment.appVesion);

      //Automatically Bust Cache for All Assets
      const links = document.querySelectorAll('link[rel="stylesheet"], script');
      links.forEach((resource) => {
        const srcOrHref = resource.getAttribute('href') || resource.getAttribute('src');
        if (srcOrHref) {
          const cacheBustedUrl = `${srcOrHref.split('?')[0]}?v=${new Date().getTime()}`;
          if (resource.tagName === 'LINK') {
            resource.setAttribute('href', cacheBustedUrl);
          } else if (resource.tagName === 'SCRIPT') {
            resource.setAttribute('src', cacheBustedUrl);
          }
        }
      });

      window.location.reload();
      //window.location.href = `${window.location.origin}${window.location.pathname}?v=${new Date().getTime()}`;
    }*/
  }

  private addFirebase() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
        () => { },
        (error) => {
          console.log('Service worker registration failed:', error);
        }
      );
    } else {
      console.log('Service workers are not supported.');
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.winRef.isMobile();
    }
    this.analyticsService.logCustomEvent('session_start', {
      time: new Date().toISOString(),
    });
  }

  ngOnDestroy(): void {
    this.analyticsService.logCustomEvent('session_end', {
      time: new Date().toISOString(),
    });
  }
}
