import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  // public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading = signal(false);


  showSpinner():void{
    this.isLoading.set(true);
  }

  hideSpinner():void{
    this.isLoading.set(false);
  }
}
