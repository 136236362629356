import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiResponse } from '@app/core/models/api-response.model';
import { ToasterService } from '@app/core/services/toaster.service';
import { Referral } from '@app/core/shared/coupon/coupon.interface';
import { CouponService } from '@app/pages/shared/services/coupon.service';
import { environment } from '@environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-share-referral',
  templateUrl: './share-referral.component.html',
  styleUrl: './share-referral.component.scss',
})
export class ShareReferralComponent implements OnInit {
  @Output() closeModals = new EventEmitter<boolean>(false);
  unSubscribeAll: Subject<void> = new Subject<void>();
  sharingURL: string = '';

  sharingPlatforms: Array<string> = [
    'whatsapp',
    'email',
    'sms',
    'telegram',
    'facebook',
    'linkedin',
    'x',
  ];

  referral: Referral = {
    title: null,
    referralCode: null,
    content: null,
  };

  copied: boolean = false;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly toaster: ToasterService,
    private readonly couponService: CouponService
  ) {}

  ngOnInit(): void {
    this.couponService
      .fetchReferralCode()
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe({
        next: (res: ApiResponse<Referral>) => {
          this.referral = res.data;
        },
        error: (error: any) => {
          console.error(error.message);
          this.toaster.error(error.message);
        },
      });
    this.sharingURL = environment.hostUrl;
  }

  closeModal(): void {
    this.closeModals.emit();
  }
  copyReferralCode() {
    this.copied = true;
    let isDataCopied = this.clipboard.copy(
      this.referral.content ?? 'Referral Code is not available.'
    );
    if (isDataCopied) {
      this.toaster.success('Text Successfully copied to clipboard.');
      setTimeout(() => {
        this.copied = false;
      }, 4000);
    } else {
      this.toaster.error('Unable to copy the referral code.');
    }
  }
}
