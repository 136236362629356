export class AppConstant {
  public static readonly TransactionFilter = [
    { value: 'all', viewValue: 'All' },
    { value: 'recharges', viewValue: 'Recharges' },
    { value: 'rewards', viewValue: 'Rewards' },
    { value: 'coins', viewValue: 'Coins' },
  ];

  public static DateFormat = {
    dateFormat: 'DD-MM-YYYY',
    displayDateFormat: 'dd-MM-yyyy',
    dateInputFormat: 'DD/MM/YYYY',
  };

  public static innerHtmlText = {
    videoErrorMessage:
      "<h1>We are experiencing video playback issues in some of the browsers.</h1> <br /> <p>Please try the following troubleshoot steps:</p><ul class = 'abc-cms-listing'><li><i class = 'arrow'></i>Log out and log back into ABC Talkies.</li><li><i class = 'arrow'></i>Refresh your browser or try playing with a different browser.</li><li><i class = 'arrow'></i>Try playing with a different internet connection.</li></ul><p>If you are still facing similar issues, we request you to reach out to us at <a class = 'abc-border-center-hover-effect' href = 'tel:+91 98733 08733' title = '+91 98733 08733'>+91 98733 08733</a> or mail us at <a class = 'abc-border-center-hover-effect' href = 'mailto:support@abctalkies.com' title = 'support@abctalkies.com'>support@abctalkies.com</a> with film details, we will assist you</p>",
    replayIcon:
      "<a class='abc-replay-icon' title='Replay' aria-label='Replay'></a>",
  };

  public static HomePageIconUrl = {
    AppStoreLink: 'https://apps.apple.com/us/app/abc-talkies/id1586757822',
    PlayStoreLink:
      'https://play.google.com/store/apps/details?id=abctalkies.boxoffice.android',
    YouTubeLink: 'https://www.youtube.com/watch?v=c1XGQIJQiZw',
  };

  public static VideoFormat = [
    'video/mp4',
    'video/webm',
    'video/mpg',
    'video/mpeg',
    'video/quicktime',
    'video/x-quicktime',
    'video/avi',
  ];

  public static FilmListText = {
    divSection: '<div class="abc-reject-status"><h6>',
    rejectedText: 'Publishing status: <span>Rejected</span>',
    closingSection: '</h6><p>',
    closeDiv: '</p></div>',
  };

  public static StaticUrl = {
    datePickerCss:
      'https://cdn.jsdelivr.net/npm/ngx-bootstrap@6.2.0/datepicker/bs-datepicker.css',
    cashFree: 'https://www.cashfree.com/assets/cashfree.sdk.v1.2.js',
    videoJs: 'https://vjs.zencdn.net/7.12.3/video.min.js',
    videoJsLink: 'https://vjs.zencdn.net/7.12.3/video-js.css',
    videoQualityLink:
      'https://unpkg.com/@silvermine/videojs-quality-selector/dist/css/quality-selector.css',
    seekJs:
      'https://cdn.jsdelivr.net/npm/videojs-seek-buttons@2.1.0/dist/videojs-seek-buttons.js',
    seekLink:
      'https://cdn.jsdelivr.net/npm/videojs-seek-buttons@2.1.0/dist/videojs-seek-buttons.css',
    intelInput:
      'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.13/build/js/intlTelInput.min.js',
    intelInputCss:
      'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.13/build/css/intlTelInput.min.css',
    dataTableLink:
      'https://cdn.datatables.net/1.11.2/css/jquery.dataTables.min.css',
    dataTableCssLink:
      'https://cdn.datatables.net/responsive/2.2.7/css/responsive.dataTables.min.css',
    dataTableScript:
      'https://cdn.datatables.net/1.11.2/js/jquery.dataTables.min.js',
    dataTableCssScript:
      'https://cdn.datatables.net/responsive/2.2.7/js/dataTables.responsive.min.js',
    videoQualityScript:
      'https://unpkg.com/@silvermine/videojs-quality-selector/dist/js/silvermine-videojs-quality-selector.min.js',
  };

  public static TransactionStatus = {
    success: 'SUCCESS',
    fail: 'FAILED',
  };
}

export const DeviceName = {
  iOS:'iOS',
  mac:'Mac'
}
