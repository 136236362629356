<header class="abc-header" [ngClass]="{ 'header-sticky': isSticky }">
  @if (isMobile && isAppNotification) {
  <div class="abc-app-notification-wrapper">
    <p>For a better experience try our app</p>
    <div class="abc-app-btns">
      <a
        href="https://play.google.com/store/apps/details?id=abctalkies.boxoffice.android"
        target="_blank"
        class="trans abc-use-app-btn"
        aria-label="Playstore"
        rel="noopener noreferrer nofollow"
      ></a>
      <a
        href="https://apps.apple.com/us/app/abc-talkies/id1586757822"
        target="_blank"
        class="trans abc-use-app-btn abc-app-icon"
        aria-label="Appstore"
        rel="noopener noreferrer nofollow"
      ></a>
    </div>
  </div>
  }
  <!-- end -->
  <div class="container">
    <div class="abc-header-inner">
      <div
        class="abc-menu-button d-lg-none"
        (click)="openAbcMenu()"
        (keypress)="openAbcMenu()"
      >
        <span class="bar bar1"></span>
        <span class="bar bar2"></span>
        <span class="bar bar3"></span>
      </div>
      <div class="abc-logo">
        @if (user?.role === 'FILM_MAKER' && isLoggedIn) { 
          <a href="javascript:void(0)" routerLink="/app/filmmaker/dashboard" title="ABC Talkies">
            <img
              src="./../../../../assets/images/abc-logo.svg"
              width="127px"
              height="50px"
              title="ABC Talkies"
              alt="ABC-Talkies"
            />
          </a>
        } @if (user?.role !== 'FILM_MAKER') { 
          <a href="javascript:void(0)" routerLink="/" title="ABC Talkies">
            <img
              src="./../../../../assets/images/abc-logo.svg"
              width="127px"
              height="50px"
              title="ABC Talkies"
              alt="ABC-Talkies"
            />
          </a>
        }
        
      </div>
      <div class="abc-header-content">
        <!-- end -->
        <div class="abc-menu">
          <div
            class="abc-menu-close-button d-lg-none"
            (click)="closeAbcMenu()"
            (keypress)="closeAbcMenu()"
          ></div>
          <ul>
            @if (user?.role === 'FILM_MAKER' && isLoggedIn) {
            <li (click)="closeSubmenu()" (keypress)="closeSubmenu()">
              <a
                href="javascript:void(0)"
                title="Dashboard"
                routerLink="/app/filmmaker/dashboard"
                routerLinkActive="active"
                >{{ "nav.Dashboard" | translate }}</a
              >
            </li>
            } @if (user?.role === 'FILM_MAKER' && isLoggedIn) {
            <li
              (click)="
                closeSubmenu(); routeToFilmMaker('app/filmmaker/create-film')
              "
              (keypress)="
                closeSubmenu(); routeToFilmMaker('app/filmmaker/create-film')
              "
            >
              <a
                routerLinkActive="active"
                [ngClass]="{
                  active: currentUrl == '/app/filmmaker/create-film'
                }"
                href="javascript:void(0)"
                title="Upload video"
              >
                {{ "nav.Upload Video" | translate }}
              </a>
            </li>
            } @if (user?.role === 'FILM_MAKER' && isLoggedIn) {
            <li
              (click)="
                closeSubmenu(); routeToFilmMaker('app/filmmaker/film-list')
              "
              (keypress)="
                closeSubmenu(); routeToFilmMaker('app/filmmaker/film-list')
              "
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{ active: currentUrl == '/app/filmmaker/film-list' }"
                title="Film list"
                routerLinkActive="active"
              >
                {{ "nav.Film List" | translate }}
              </a>
            </li>
            }
            <li (click)="closeSubmenu()" (keypress)="closeSubmenu()">
              <a
                href="javascript:void(0)"
                routerLinkActive="active"
                title="I'm a Filmmaker"
                routerLink="/app/iamfilmmaker"
              >
                @if (user?.role !== 'FILM_MAKER') {
                <span>I'm a Filmmaker</span>
                } @if (user?.role === 'FILM_MAKER' && isLoggedIn) {
                <span>Features</span>
                }
              </a>
            </li>
            @if (this.bigShortService.isBigShortChallengeActive()) {
            <li (click)="closeSubmenu()" (keypress)="closeSubmenu()">
              <a
                href="javascript:void(0)"
                routerLink="/static/big-shorts-challenge"
                title="Film list"
                [ngClass]="{
                  active: currentUrl == '/static/big-shorts-challenge'
                }"
                routerLinkActive="active"
                >Big Short Challenge
              </a>
            </li>
            }
          </ul>
        </div>

        <div class="abc-search-account">
          <div class="abc-header-search">
            <form
              class="search-form"
              autocomplete="off"
              role="search"
              (ngSubmit)="goToSearch(searchVal.value)"
            >
              <div class="position-relative w-100">
                <input
                  [(ngModel)]="search"
                  #searchVal
                  [formControl]="queryField"
                  class="form-control"
                  placeholder="Search"
                  name="searches"
                  (focus)="focus = true"
                  [ngClass]="{ searchopen: focus }"
                  type="search"
                  aria-label="Search through film list"
                  id="film-search"
                  (click)="onclick()"
                />
                @if (isMatches && focus) {
                <div
                  class="dropdown-menu dropdown-film-list-menu"
                  id="film-search-dropdown"
                >
                  <ul class="custom-list-group">
                    @if (matches?.genre) {
                    <li
                      class="custom-list-group-item"
                      #list
                      (mouseenter)="hoverListItem(list.classList, true)"
                      (mouseleave)="hoverListItem(list.classList, false)"
                    >
                      <a
                        href="javascript:void(0)"
                        routerLink="/View-film-list/genre"
                        [queryParams]="{ id: matches?.genre?._id }"
                        (click)="closeAbcSearch()"
                      >
                        <span class="thumbnail-image">
                          @if (matches?.genre?.genreUrl) {
                          <img
                            src="{{ matches?.genre?.genreUrl }}"
                            alt="Abc-thumbnail"
                          />
                          } @if (!matches?.genre?.genreUrl) {
                          <img
                            src="{{
                              cdnUrl
                            }}/assets/images/abc-blank-upload-image.jpg"
                            alt="blank-upload"
                          />
                          }
                        </span>
                        <p class="search-content-wrapper">
                          <span class="search-heading">{{
                            matches?.genre?.name
                          }}</span>
                          <!-- Tag -->
                          <span class="abc-font-style-12 abc-search-lable"
                            >Genre</span
                          >
                        </p>
                      </a>
                    </li>
                    } @if (matches?.language) {
                    <li
                      class="custom-list-group-item"
                      #list
                      (mouseenter)="hoverListItem(list.classList, true)"
                      (mouseleave)="hoverListItem(list.classList, false)"
                    >
                      <a
                        href="javascript:void(0);"
                        routerLink="/View-film-list/language"
                        [queryParams]="{ id: matches?.language?._id }"
                        (click)="closeAbcSearch()"
                      >
                        <span class="thumbnail-imagelang">
                          @if (matches?.language?.languageUrl) {
                          <img
                            src="{{ matches?.language?.languageUrl }}"
                            alt="thumbnail"
                          />
                          } @if (!matches?.language?.languageUrl) {
                          <img
                            src="{{
                              cdnUrl
                            }}/assets/images/abc-blank-upload-image.jpg"
                            alt="abc-blank-upload"
                          />
                          }
                        </span>
                        <p class="search-content-wrapper">
                          <span class="search-heading">
                            {{ matches?.language?.name }}
                          </span>
                          <!-- Tag -->
                          <span class="abc-font-style-12 abc-search-lable">
                            Language
                          </span>
                        </p>
                      </a>
                    </li>
                    } @if (matches?.filmMaker) {
                    <li
                      class="custom-list-group-item"
                      #list
                      (mouseenter)="hoverListItem(list.classList, true)"
                      (mouseleave)="hoverListItem(list.classList, false)"
                    >
                      <a
                        href="javascript:void(0)"
                        routerLink="/filmmaker-detail/{{
                          matches?.filmMaker?._id
                        }}"
                        (click)="closeAbcSearch()"
                      >
                        <span class="thumbnail-image">
                          @if (matches?.filmMaker?.logoUrl) {
                          <img
                            alt="Abc-thumbnail"
                            src="{{ matches?.filmMaker?.logoUrl }}"
                          />
                          } @if (!matches?.filmMaker?.logoUrl) {
                          <img
                            alt=""
                            src="{{
                              cdnUrl
                            }}/assets/images/abc-blank-upload-image.jpg"
                          />
                          }
                        </span>
                        <p class="search-content-wrapper">
                          <span class="search-heading">
                            {{ matches?.filmMaker?.productionHouse }}
                          </span>
                          <!-- Tag -->
                          <span class="abc-font-style-12 abc-search-lable">
                            FilmMaker
                          </span>
                        </p>
                      </a>
                    </li>
                    } @for (match of matches?.films; track
                    trackByMethod($index)) {
                    <li
                      class="custom-list-group-item"
                      #list
                      (mouseenter)="hoverListItem(list.classList, true)"
                      (mouseleave)="hoverListItem(list.classList, false)"
                    >
                      <a
                        href="javascript:void(0)"
                        routerLink="/app/movie-detail/{{ match._id }}"
                        (click)="closeAbcSearch()"
                      >
                        <span class="thumbnail-image">
                          @if (match?.portrait) {
                          <img
                            alt=""
                            src="{{ match?.portrait }}"
                            alt="Abc-thumbnail-image"
                          />
                          } @if (!match?.portrait) {
                          <img
                            alt=""
                            src="{{
                              cdnUrl
                            }}/assets/images/abc-blank-upload-image.jpg"
                          />
                          }
                        </span>
                        <p class="search-content-wrapper">
                          <span class="search-heading">{{ match?.title }}</span>
                          <span
                            class="search-small-text abc-font-style-12 d-block"
                          >
                            {{ match?.genre }}
                          </span>
                        </p>
                      </a>
                    </li>
                    }
                    <li class="search-more-reuslt">
                      <a
                        href="javascript:void(0)"
                        title="More results"
                        class="abc-border-center-hover-effect"
                        (click)="goToSearch(search)"
                      >
                        More results
                      </a>
                    </li>
                  </ul>
                </div>
                }
              </div>
              <button
                class="close-btn"
                type="button"
                (click)="closeAbcSearch()"
              ></button>
            </form>
            <button
              class="abc-search-icon"
              name=""
              aria-label="Search-icon"
              (click)="openAbcSearch()"
            >
              &nbsp;
            </button>
            @if (!isMatches && focus && search) {
            <ul
              class="custom-list-group dropdown-menu"
              [ngClass]="{ searchopen: focus }"
            >
              <li class="custom-list-group-item">
                <p>No results found</p>
              </li>
            </ul>
            }
          </div>
          <!-- end -->
          <div class="account-dropdown d-flex" #myDiv>
            @if (user?.role !== 'FILM_MAKER' && isLoggedIn) {
            <div
              id="abc-notification"
              class="user-icon abc-notification"
              (click)="openNotification()"
              (keypress)="openNotification()"
            >
              @if (isNotificationPush) {
              <span class="abc-show-notification"></span>
              }
              <div
                class="abc-dropdown abc-right-dropdown abc-notification-dropdown"
              >
                <div
                  class="d-flex flex-wrap justify-content-between abc-dropdwon-heading"
                >
                  <h5 class="green text-uppercase">Notifications</h5>
                  <!-- end -->
                  @if (notificationList.length !== 0) {
                  <div
                    class="abc-green-font abc-font-style-12"
                    (click)="markAllRead()"
                    (keypress)="markAllRead()"
                  >
                    Mark all as read
                  </div>
                  }
                </div>
                <!-- end -->
                <ul class="abc-notification-list abc-custom-scrollbar">
                  @if (notificationList.length === 0) {
                  <div
                    class="abc-no-notification-found"
                    *ngIf="notificationList.length === 0"
                  >
                    <img
                      class="abc-no-notification-vector"
                      src="{{ cdnUrl }}/assets/images/abc-no-notification.svg"
                      alt="No-notification"
                    />
                    <div class="abc-h4-common-style">No Notifications</div>
                  </div>
                  } @for ( notification of notificationList; track
                  trackByMethod($index)) {
                  <span>
                    @if (notification.read === false) {
                    <li
                      class="abc-show-notification"
                      (click)="onRedirect(notification)"
                      (keypress)="onRedirect(notification)"
                    >
                      <div class="abc-font-style-12 green d-block">
                        {{ notification?.title }}
                      </div>
                      <span>{{ notification?.message }}</span>
                      <span class="abc-font-style-12 green d-block">
                        {{ notification?.createdAt | date : "d MMM, y" }}
                      </span>
                    </li>
                    }
                    <!-- <hr> -->
                    @if (notification.read === true) {
                    <li
                      (click)="onRedirect(notification)"
                      (keypress)="onRedirect(notification)"
                    >
                      <div class="abc-font-style-12 green d-block">
                        {{ notification?.title }}
                      </div>
                      <span>{{ notification?.message }}</span>
                      <span class="abc-font-style-12 green d-block">
                        {{ notification?.createdAt | date : "d MMM, y" }}
                      </span>
                    </li>
                    }
                    <!-- <hr> -->
                  </span>
                  }
                </ul>
              </div>
            </div>
            }
            <!-- end -->
            @if (!isLoggedIn) {
            <a href="javascript:void(0)" title="sign in" routerLink="/sign-in">
              <div class="user-icon">
                <img src="{{ cdnUrl }}/assets/images/user.svg" alt="Abc-user" />
              </div>
            </a>
            } @if (isLoggedIn) {
            <div
              type="button"
              id="profile-icon"
              class="user-icon"
              (click)="openProfile()"
              (keypress)="openProfile()"
            >
              @if (user.profilePicUrl) {
              <img
                src="{{ user.profilePicUrl }}"
                alt="Abc-user"
                class="abc-user-full-image"
              />
              } @if (!user.profilePicUrl) {
              <img
                src="{{ cdnUrl }}/assets/images/abc-user-avtar.svg"
                alt="Abc-user"
              />
              }
              <ul class="abc-dropdown abc-right-dropdown">
                <li>
                  <a
                    href="javascript:void(0)"
                    title="Profile"
                    routerLink="/app/profile/viewer"
                    routerLinkActive="active"
                  >
                    {{ "nav.Profile" | translate }}
                  </a>
                </li>
                @if (user?.role === 'FILM_MAKER') {
                <li>
                  <a
                    href="javascript:void(0)"
                    title="Dashboard"
                    routerLink="/app/filmmaker/dashboard"
                    routerLinkActive="active"
                  >
                    {{ "nav.Dashboard" | translate }}
                  </a>
                </li>
                } @if ( user?.role === 'FILM_MAKER' &&
                user.otherRoles.includes('VIEWER') ) {
                <li>
                  <a (click)="switchRole('VIEWER')" title="Switch to viewer">
                    Switch To Viewer
                  </a>
                </li>
                } @if (checkForRoles()) {
                <li>
                  <a
                    href="javascript:void(0)"
                    title="Switch to film maker"
                    (click)="switchRole('FILM_MAKER')"
                  >
                    Switch To Film Maker
                  </a>
                </li>
                } @if(user?.role === 'VIEWER' && userProfile &&
                userProfile.isReferralCodeSettingsEnable){
                <li>
                  <a (click)="openReferralDialog(shareReferral)">
                    {{ "nav.ShareReferral" | translate }}
                  </a>
                </li>
                }
                <li>
                  <a
                    href="javascript:void(0)"
                    (click)="onClickLogout()"
                    title="Logout"
                  >
                    {{ "nav.Logout" | translate }}
                  </a>
                </li>
              </ul>
            </div>
            }
          </div>
          <!-- end -->
        </div>
      </div>
    </div>
  </div>
</header>
<ng-template #shareReferral>
  <app-share-referral (closeModals)="closeReferralDialog()" />
</ng-template>
