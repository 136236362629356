import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginType } from '@app/auth/shared/interfaces';
import {
  CommonResponse,
  ForgotPassInput,
  LoginWithOTPModel,
  OtpData,
  OtpSignInData,
  ResetPassInput,
  SignInData,
  SignInResult,
  SignupData,
  VerifyOtpInput,
} from '@app/auth/shared/interfaces/sign-in-result.interface';
import { Token } from '@app/auth/shared/interfaces/token.interface';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { UserCountryService } from '@app/public/shared/services/user-country.service';
import { User } from '@core/shared/user';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly USER_ITEM = '_user';
  private readonly TOKEN_ITEM = '_token';

  baseUrl: string = environment.apiUrl;
  public userSubject = new BehaviorSubject<User | null>(this._getUser());
  private readonly tokenSubject = new BehaviorSubject<Token | null>(
    this._getToken()
  );

  isLoggedIn = new BehaviorSubject<boolean>(this.loggedIn);
  userDataFromSinIn: any;

  constructor(
    public http: HttpClient,
    public localStorageService: LocalStorageService,
    private readonly userCountryService: UserCountryService
  ) {}

  get userValue(): User | null {
    return this.userSubject?.value;
  }

  get tokenValue(): Token | null {
    return this.tokenSubject?.value;
  }

  private get loggedIn(): boolean {
    const user = this.userSubject?.value;
    const token = this.tokenSubject?.value;
    return !!(user && token);
  }

  signIn(signinInfo: SignInData): Observable<SignInResult> {
    return this.http.post<any>(`${this.baseUrl}/user/login`, signinInfo).pipe(
      tap((response) => {
        if (
          response.data &&
          response.data.countryCode &&
          response.data.countryCode.trim() !== ''
        ) {
          this.userCountryService.setCountryCode(response.data.countryCode);
        }
      })
    );
  }

  sendOTP(phoneData: OtpData, type: number): Observable<SignInResult> {
    let url = `${this.baseUrl}/user/get-otp`;
    url = type === 1 ? url : `${url}?type=NEW`;
    return this.http.post<any>(url, phoneData);
  }

  loginWithOTP(
    payload: LoginWithOTPModel,
    loginType: LoginType = LoginType.email,
    isVerify: boolean = false
  ) {
    let url = `${this.baseUrl}/user/login-with-otp?verify=${isVerify}&loginType=${loginType}`;
    return this.http.post<any>(url, payload);
  }

  otpSignIn(otpsigninInfo: OtpSignInData): Observable<SignInResult> {
    return this.http
      .post<SignInResult>(`${this.baseUrl}/user/login-via-otp`, otpsigninInfo)
      .pipe(
        tap((response) => {
          if (
            response.data?.countryCode &&
            response.data.countryCode.trim() !== ''
          ) {
            this.userCountryService.setCountryCode(response.data.countryCode);
          }
        })
      );
  }

  logout(): void {
    this.localStorageService.remove(this.USER_ITEM);
    this.localStorageService.remove(this.TOKEN_ITEM);
    this.userSubject.next(null);
    this.tokenSubject.next(null);
    this.isLoggedIn.next(false);
  }

  signup(signupInfo: SignupData): Observable<SignInResult> {
    return this.http.post<SignInResult>(
      `${this.baseUrl}/user/signup`,
      signupInfo
    );
  }

  forgotPassword(
    forgotPasswordData: ForgotPassInput
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${this.baseUrl}/user/forgot-password`,
      forgotPasswordData
    );
  }

  verifyForgotOTP(verifyOTPData: VerifyOtpInput): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${this.baseUrl}/user/verify-otp`,
      verifyOTPData
    );
  }

  resetPassword(resetPassData: ResetPassInput): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${this.baseUrl}/user/reset-password`,
      resetPassData
    );
  }

  getGenre(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${this.baseUrl}/genre`);
  }

  updateGenre(prefIds: any): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(
      `${this.baseUrl}/user/update-preference`,
      { preference: prefIds }
    );
  }

  filmMakerSignup(signupInfo: any): Observable<SignInResult> {
    return this.http.post<SignInResult>(
      `${this.baseUrl}/user/signup`,
      signupInfo
    );
  }

  switchUserRole(
    role: string,
    isNotification: boolean
  ): Observable<SignInResult> {
    return this.http.get<SignInResult>(
      `${this.baseUrl}/user/switch-role?role=${role}&isNotificationSend=${isNotification}`
    );
  }

  emailVerify(token: string): Observable<SignInResult> {
    const headers = new HttpHeaders({
      deviceType: 'web',
    });

    return this.http.get<SignInResult>(
      `${this.baseUrl}/user/verify-email?token=${token}`,
      { headers }
    );
  }

  applyUserRole(rolename: string): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/user/switch-role?role=${rolename}`,
      {}
    );
  }

  _getUser(): User {
    // tslint:disable-next-line:no-non-null-assertion
    return JSON.parse(this.localStorageService.get(this.USER_ITEM)) as User;
  }

  _getToken(): Token {
    // tslint:disable-next-line:no-non-null-assertion
    return JSON.parse(this.localStorageService.get(this.TOKEN_ITEM)) as Token;
  }

  _saveUser(user: User): void {
    this.localStorageService.set(this.USER_ITEM, JSON.stringify(user));
    this.userSubject.next(user);
    this.isLoggedIn.next(true);
  }

  _saveToken(token: Token): void {
    this.localStorageService.set(this.TOKEN_ITEM, JSON.stringify(token));
    this.tokenSubject.next(token);
  }

  socialSignup(userData: any): Observable<SignInResult> {
    return this.http
      .post<SignInResult>(`${this.baseUrl}/user/social/signUp`, userData)
      .pipe(
        tap((response) => {
          if (
            response.data &&
            response.data.countryCode &&
            response.data.countryCode.trim() !== ''
          ) {
            this.userCountryService.setCountryCode(response.data.countryCode);
          }
        })
      );
  }

  getCountry(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${this.baseUrl}/user/country-list`);
  }
}
