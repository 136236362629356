import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Observable } from 'rxjs';
import { Path } from '../enums';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    public abcSpinner: SpinnerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformBrowser(this.platformId)) {
      const isLoggedIn = this.authService.isLoggedIn.value;
      if (isLoggedIn) {
        return true;
      }
      // if not logged in redirects to sign-in page with the return url
      this.router.navigate([`/${Path.SignIn}`], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    this.abcSpinner.isLoading.set(true);
    return false;
  }
}
