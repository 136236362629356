<div class="abc-popup-custom-design">
  <div class="modal-header">
    <h3>Refer & Earn</h3>
    <button class="close trans" (click)="closeModal()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="abc-popup-content-area">
      <div>
        <h4 class="abc-green-font">Invite your friend</h4>
        <p class="text mt-10">
          Share the code below and earn rewards when your friends sign up!
        </p>
      </div>

      <div class="referral-code-container abc-input-field mt-20">
        <input type="text" id="referralCode" value={{referral.referralCode}} readonly />
        <div class="abc-form-button abc-send-OTP">
          <button
            class="only-button"
            [disabled]="copied"
            (click)="copyReferralCode()"
          >
            {{ copied ? "Copied" : "Copy" }}
          </button>
        </div>
      </div>
      <div class="share-buttons">
        <share-buttons
          [include]="sharingPlatforms"
          [url]="sharingURL"
          theme="circles-dark"
          showText="true"
          [title]="referral.title ?? 'Unlock Exclusive Reward with My Referral Code!'"
          [description]="referral.content ?? ''"
          show="5"
          size="2"
        />
      </div>
    </div>
  </div>
</div>
