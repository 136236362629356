import { HttpClient } from '@angular/common/http';
import {
  Injectable,
  PLATFORM_ID,
  Inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { User } from '@app/core/shared/user';
import { environment } from '@environments/environment';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserCountryService {
  private readonly baseUrl: string = environment.apiUrl;
  _countryCode: WritableSignal<string> = signal('IN');
  private readonly USER_ITEM = '_user';

  constructor(
    private readonly http: HttpClient,
    public localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private readonly platformId: object // Inject platform ID for SSR check
  ) {}

  _getUser(): User | null {
    const user = this.localStorageService.get(this.USER_ITEM);
    return user ? (JSON.parse(user) as User) : null;
  }

  setCountryCode(value: string) {
    if (value) {
      this._countryCode.set(value.toUpperCase());
    }
  }

  initialCountryCodeSetupByIp(): Promise<void> {
    const user = this._getUser();
    return new Promise((resolve, reject) => {
      if (user?.countryCode) {
        this.setCountryCode(user.countryCode);
        resolve();
      } else if (isPlatformServer(this.platformId)) {
        console.warn('IP-based country lookup skipped in SSR environment');
        resolve();
      } else {
        this.http.get('https://api.country.is').subscribe({
          next: (response: any) => {
            this.setCountryCode(response.country);
            resolve();
          },
          error: (error) => {
            console.error('Failed to get country from api.country.is:', error);
            this.getCountryCodeFromBackend().then(resolve).catch(reject);
          },
        });
      }
    });
  }

  private getCountryCodeFromBackend(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get('https://api.ipify.org?format=json').subscribe({
        next: (ipResponse: any) => {
          this.http
            .get<{ data: { countryCode: string } }>(
              `${this.baseUrl}/user/ipDetails/${ipResponse.ip}`
            )
            .subscribe({
              next: (res) => {
                if (res?.data?.countryCode) {
                  this.setCountryCode(res.data.countryCode);
                }
                resolve();
              },
              error: (error) => {
                console.error(
                  'Failed to get country code from backend:',
                  error
                );
                reject(new Error(error));
              },
            });
        },
        error: (error) => {
          console.error('Failed to get IP address from api.ipify.org:', error);
          reject(new Error(error));
        },
      });
    });
  }
}
