import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  getClientMessage(error: Error): string {
    return !navigator.onLine ? 'No Internet Connection' : error.message || error.toString();
  }

  getClientStack(error: Error): string | undefined {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message || 'An unknown server error occurred';
  }

  getServerStack(error: HttpErrorResponse): string {
    // Extract relevant fields only to avoid circular references.
    const cleanError = {
      message: error.message,
      status: error.status,
      url: error.url,
      name: error.name,
    };
    return this.safeStringify(cleanError);
  }

  private safeStringify(value: any): string {
    const cache = new Set();
    return JSON.stringify(value, (key, val) => {
      if (typeof val === 'object' && val !== null) {
        if (cache.has(val)) return; // Circular reference found, discard key
        cache.add(val);
      }
      return val;
    });
  }
}
