export enum AdsType {
  mid = 'mid',
  pre = 'pre',
}

export enum VideoQuality {
  Q1080p = 1,
  Q720p = 2,
  Q480p = 3,
  Q360p = 4,
}

export enum PlaybackType {
  preview = 'PREVIEW',
  film = 'FILM',
}
