<div class="abc-error-page">
  <div class="inner-wrap text-center">
    <div
      class="abc-404-image-warpper position-relative d-md-flex flex-md-column align-md-items-center justify-md-content-center"
    >
      <img
        class="d-block d-md-none"
        src="{{cdnUrl}}/assets/images/abc-mobile-404.svg"
        alt="Abc-404-image"
      />
      <div class="abc-404-images-part d-none d-md-block">
        <object
          class="abc-404-center-image"
          data="../../../../assets/images/abc-404.svg"
        ></object>
        <div class="abc-404-line-top"></div>
        <object
          class="abc-404-reel"
          data="../../../../assets/images/abc-404-reel.svg"
        ></object>
        <div class="abc-404-line-bottom"></div>
      </div>
    </div>
    <div class="content-wrap">
      <h2>Uh oh!</h2>
      <p>This page is not available at ABC Talkies.<br /> Click below to navigate to Box Office.</p>
    </div>
    <div class="abc-form-button">
      <button
        class="only-button"
        type="button"
        title="Go to Box Office"
        (click)="routeToHome()"
      >
        Go to Box Office
      </button>
    </div>
  </div>
</div>
