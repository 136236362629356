import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

export interface Options {
  timeOut: number;
  extendedTimeOut?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(
    public toastr: ToastrService,
    private translate: TranslateService
  ) {}

  translateMessage(message: string): string {
    let tMessage = message;
    if (message.includes('toaster')) {
      this.translate.get(message).subscribe((res: string) => {
        tMessage = res;
      });
    } 
    return tMessage;
  }

  success(message: string, title?: string) {
    let translatedMessage = this.translateMessage(message);
    this.toastr.success(translatedMessage, title);
  }

  error(message: string, title?: string, options?: Options) {
    let translatedMessage = this.translateMessage(message);
    this.toastr.error(translatedMessage, title, options);
  }

  info(body: string, title?: string, options?: Options) {
    let translatedBody = this.translateMessage(body);
    this.toastr.info(translatedBody, title, options);
  }
}
