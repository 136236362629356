<footer class="abc-footer">
  <div class="abc-footer-top">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-7 col-xl-6">
          <div class="abc-footer-links">
            <ul>
              <li>
                <a
                  [routerLink]="['/static/about-us']"
                  routerLinkActive="active"
                  title="About ABC Talkies"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.About ABC Talkies" | translate }}</a
                >
              </li>
              <li>
                <a
                  routerLink="/static/privacy-policy"
                  routerLinkActive="active"
                  title="Privacy policy"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.Privacy Policy" | translate }}</a
                >
              </li>
              <li>
                <a
                  routerLink="/static/copyright-policy"
                  routerLinkActive="active"
                  title="Copyright policy"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.Copyright Policy" | translate }}</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/static/cookies-policy']"
                  routerLinkActive="active"
                  title="Cookies policy"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.Cookies Policy" | translate }}</a
                >
              </li>
              <li>
                <a
                  routerLink="/static/filmmaker-terms-conditions"
                  routerLinkActive="active"
                  title="Filmmaker contract"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.Filmmaker Contract" | translate }}</a
                >
              </li>
            </ul>
            <ul>
              @if (this.bigShortService.isBigShortChallengeActive()) {
                <li>
                  <a
                    href="javascript:void(0)"
                    routerLink="/static/big-shorts-challenge"
                    routerLinkActive="active"
                    title="Film Contest"
                    class="abc-border-left-hover-effect"
                  >
                    {{ "ft.Film Contest" | translate }}
                  </a>
                </li>
              }

              <li>
                <a
                  routerLink="/static/payment-refund-policy"
                  routerLinkActive="active"
                  title="Payment cancellation & refund policy"
                  class="abc-border-left-hover-effect"
                  >{{
                    "ft.Payment Cancellation & Refund Policy" | translate
                  }}</a
                >
              </li>
              <li>
                <a
                  routerLink="/static/user-terms-conditions"
                  routerLinkActive="active"
                  title="User terms of use"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.User Terms of Use" | translate }}</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/static/contact-us']"
                  routerLinkActive="active"
                  title="Contact us"
                  class="abc-border-left-hover-effect"
                  >{{ "ft.Contact Us" | translate }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-5 col-xl-6">
          <div class="abc-footer-socials">
            <div class="socials-follow-us mt-4 mt-md-0">
              <p class="mb-15">{{ "ft.Follow us on" | translate }}</p>
              <ul class="d-flex">
                <li>
                  <a
                    href="https://www.facebook.com/abctalkies/"
                    target="_blank"
                    title="Facebook"
                    class="fb"
                    rel="noopener noreferrer nofollow"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/abctalkies/"
                    target="_blank"
                    title="Instagram"
                    class="in"
                    rel="noopener noreferrer nofollow"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/AbcTalkies"
                    target="_blank"
                    title="Twitter"
                    class="tw"
                    rel="noopener noreferrer nofollow"
                  ></a>
                </li>
              </ul>
            </div>
            <div class="socials-apps">
              <p class="mb-15">{{ "ft.Apps available on" | translate }}</p>
              <ul class="d-flex">
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=abctalkies.boxoffice.android"
                    target="_blank"
                    class="google-play trans"
                    aria-label="Google-Playstore"
                    rel="noopener noreferrer nofollow"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/abc-talkies/id1586757822"
                    target="_blank"
                    class="app-store trans"
                    aria-label="App-store"
                    rel="noopener noreferrer nofollow"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="abc-footer-bottom text-center">
    <div class="container">
      <p>
        &copy; {{ date.getFullYear() }}
        {{ "iAMFilmmaker.ABC Talkies" | translate }}.
        {{ "ft.All rights Reserved" | translate }}.
      </p>
    </div>
  </div>
</footer>
