import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@app/core/models/api-response.model';
import {
  LoginReferralPayload,
  LoginReferralSetting,
  Referral,
} from '@app/core/shared/coupon/coupon.interface';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  baseUrl: string = environment.apiUrl;
  constructor(private readonly httpClient: HttpClient) {}

  verifyCoupon(data: any): Observable<any> {
    let payload = { ...data, deviceType: 'Web' };
    return this.httpClient.post(
      `${this.baseUrl}/viewer/redeem-coupon`,
      payload
    );
  }

  fetchReferralCode(): Observable<ApiResponse<Referral>> {
    return this.httpClient.get<ApiResponse<Referral>>(
      `${this.baseUrl}/user/referralCode`
    );
  }

  verifyLoginReferral(payload: LoginReferralPayload): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/viewer/validate-referral-login`,
      payload,
      { headers: { Authorization: 'Basic SmF6ek11c2ljQDEyMw==' } }
    );
  }

  checkReferralSettingEnable(): Observable<ApiResponse<LoginReferralSetting>> {
    return this.httpClient.get<ApiResponse<LoginReferralSetting>>(
      `${this.baseUrl}/viewer/getLoginCoinSettings`
    );
  }
}
