<div class="layout-wrapper">
  <app-navbar></app-navbar>
  <router-outlet></router-outlet>
  <div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
    <div class="abc-back-to-top">
      <button class="scrollTop" (click)="scrollToTop()" aria-label="scroll top">
        <span class="top-arrow"></span>
      </button>
    </div>
  </div>
  @if (ispopupshow === null) {
    <div class="abc-cookies-policy-box">
      <p>
        We use cookies to optimize your experience, analyze traffic, and
        personalize content. To learn more, please visit our
        <a
          class="abc-border-center-hover-effect"
          href="javascript:void()"
          routerLink="/static/cookies-policy"
          target="_blank"
          rel="noopener noreferrer"
          >Cookie Policy</a
          >. By using our site without disabling cookies, you consent to our use of
          them.
        </p>
        <a
          href="javascript:void()"
          title="Close"
          (click)="closePopup()"
          class="abc-cookies-policy-close"
        ></a>
      </div>
    }
  </div>
  <app-footer></app-footer>
