import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserCountryService } from '@app/public/shared/services/user-country.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  apiUrl: string = environment.apiUrl;
  constructor(private authService: AuthService, private userCountryService: UserCountryService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const user = this.authService.userValue;
    const token = this.authService.tokenValue;
    const isLoggedIn = user && token;
    const SKIP_AUTH = 'skip-Auth';

    if (isLoggedIn && token && !request.url.includes('assets') && !request.headers.has(SKIP_AUTH)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    if (request.headers.has(SKIP_AUTH) && request.headers.get(SKIP_AUTH) === 'true') {
      const headers = request.headers.delete(SKIP_AUTH);
      request = request.clone({ headers });
    }

    if (request.method === 'GET' && request.url.toLowerCase().startsWith(this.apiUrl.toLowerCase())) {
      const params = new HttpParams({ fromString: request.params.toString() }).set('countryCode', this.userCountryService._countryCode());
      request = request.clone({
        params
      });
    }

    return next.handle(request);
  }
}
