import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppConstant } from '@app/core/constant/app-constant';
import { BigShortService } from '@app/public/shared/services/big-short.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  date: Date = new Date();
  readonly bigShortURL = AppConstant.StaticUrl.bigShortUrl;
  constructor(protected bigShortService : BigShortService) {}


  ngOnInit(): void {}
}
