import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ToasterService } from '@app/core/services/toaster.service';
import { ErrorService, LoggerService } from '../services';
import { SpinnerService } from '../services/spinner.service';
// import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private readonly ngZone: NgZone
  ) {}

  handleError(error: Error | HttpErrorResponse): void {
    const errorService = this.injector.get(ErrorService);
    const loggerService = this.injector.get(LoggerService);
    const toastr = this.injector.get(ToasterService);
    const spinner = this.injector.get(SpinnerService);
    // const spinner = this.injector.get(NgxSpinnerService);

    let message: string;
    let stackTrace: any;
    // console.log('errorhandler', JSON.stringify(error));
    if (error instanceof HttpErrorResponse) {
      stackTrace = errorService.getServerStack(error);
      message = error.error.message;
      this.ngZone.run(() => {
        if (message) {
          toastr.error(message, '');
        }
      });
      // spinner.hide();
      spinner.isLoading.set(false);
    } else {
      message = error.message;
      stackTrace = errorService.getClientStack(error);
      // this.ngZone.run(() => {
      //   toastr.error(message, '');
      // });
    }
    loggerService.logError(message, stackTrace);
  }
}
